exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-challenge-tsx": () => import("./../../../src/pages/challenge.tsx" /* webpackChunkName: "component---src-pages-challenge-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-nerdery-tsx": () => import("./../../../src/pages/nerdery.tsx" /* webpackChunkName: "component---src-pages-nerdery-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-us-tsx": () => import("./../../../src/pages/us.tsx" /* webpackChunkName: "component---src-pages-us-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-jobs-details-tsx": () => import("./../../../src/templates/jobs-details.tsx" /* webpackChunkName: "component---src-templates-jobs-details-tsx" */)
}

