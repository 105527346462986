/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './src/styles/theme';
import { GlobalStyle } from './src/styles/global';
import './src/styles/typography.css';

export const wrapPageElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  );
};
