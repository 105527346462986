import * as styled from 'styled-components';

export const GlobalStyle = styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }
  html {
    font-size: 10px;

    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
      font-size: 50%;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
      #iubenda-cs-banner {
        padding: 12px 16px;
        border-radius: 0;
        width: 100%;
        margin-left: 0;
        transform: translate(0%, 0%);
      }
    }
  }

  body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    font-family: work-sans, sans-serif;
  }

  ul,
  ol {
    list-style: none;
  }

  img {
    max-width: 100%;
    display: block;
  }
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: inherit;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .lock-scroll {
    overflow: hidden;
  }

  /* // Iubenda cookie banner */
  #iubenda-cs-banner {
    max-width: 750px;
    border-radius: 30px;
    font-family: work-sans, sans-serif;
    font-size: 11px;
    color: white;
    background-color: rgb(22, 22, 22);
    padding: 4px 16px;
    margin-left: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translate(0%, -50%);
    z-index: 2147483002; // 1 higher than Intercom
  }
  .iub__us-widget,
  .iubenda-cs-preferences-link {
    display: none;
  }
  .iubenda-cs-opt-group-custom {
    margin-left: 16px;
  }
  .iubenda-cs-opt-group {
    display: flex;
  }
  .iubenda-cs-rationale {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #iubenda-cs-banner button {
    font-size: 11px;
    background-color: white;
    border: 1px solid #161616;
    border-radius: 10px;
    font-family: work-sans, sans-serif;
    margin-left: 4px;
    padding: 4px 8px;
    min-width: 70px;
    color: #161616;
  }
  #iubenda-cs-banner a,
  #iubenda-cs-banner a:visited {
    color: white;
  }
  /* // End cookie banner */
`;
